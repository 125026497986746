/*
Theme Name: CFC Foodcleaning
Theme URI: https://www.reign.agency
Author: Reign Agency | Expand your business
Author URI: https://www.reign.agency

*/

@import "vendor/bootstrap/bootstrap";
@import "vendor/slick";
@import "vendor/cookie";

@mixin transition(){
  transition:all 0.1s cubic-bezier(0.84, 0.38, 0.83, 0.67)
}

$pC: #1E89B9;
$sC: #F9B233;
$black: #7E7E7E;
$grey: #F5F5F5;

.lazyload,
.lazyloading{
  opacity: 0;
  @include transition();
}
.lazyloaded{
  opacity: 1;
  @include transition();
}

* {
  outline: 0 !important;
  text-decoration: none !important;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-family: "Dosis", sans-serif;
  color: $black;
  font-size: 1.25rem;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'co-headline', sans-serif;
}
section{
  margin-bottom: 50px;
}
.text-block{
  margin-bottom: 50px;
}
h1 {
  font-size: 1.5rem;
}

p {
  margin-bottom: 30px;
}

figure {
  margin: 0;
}

img.img-fluid {
  max-width: none;
  width: 100%;
}

ul, li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.table td, .table th{
  vertical-align: middle;
}
label {
  cursor: pointer !important;
}
.row{
  margin: 0;
}
input, textarea, select {
  width: 100%;
  padding: 6px 15px;
  font-weight: 300;
  border-color: #d6d6d6;
  border-width: 2px !important;
  border-radius: 30px;
  border-style: solid;
  background: transparent;
  resize: none;
  @include transition();
}
input[type="checkbox"],
input[type="radio"]{
  width: auto;
}

.wpcf7 label {
  display: block;
}
input[type="submit"]{
  width: auto;
  border-radius: 0;
}
input:not([type="submit"]):focus, textarea:focus {
  border-color: $pC;
}
input:disabled{
  background: none;
  opacity: .6;
}
a {
  color: #4d4d4d;
  @include transition();
  &:hover{
    color: $sC;
  }
}
.button {
  width: auto;
  border-radius: 0;
  background: $pC;
  color: #fff;
  padding: 7px 25px;
  font-size: .8em;
  display: inline-block;
  text-transform: lowercase;
  cursor: pointer;
  border: 2px solid transparent;
  &.lined{
    border: 2px solid #fff;
    background: transparent;
    color: #fff;
    &:hover{
      background: #fff;
      color: $pC;
    }
    &.blue{
      border-color: $pC;
      color: $pC;
      &:hover{
        background: $pC;
        color: #fff;
      }
    }
  }
  &:hover, &:focus, &:active {
    background: darken($pC, 10%);
    color: #fff;
  }
  &.orange{
    background: $sC;
    &:hover{
      background: darken($sC, 10%);
      color: #fff;
    }
  }
}

.wpcf7 div.wpcf7-mail-sent-ng, .screen-reader-response[role="alert"], .wpcf7-response-output, .wpcf7-not-valid-tip {
  padding: 10px 20px;
  margin: 10px 0;
  color: #fff;
  border-radius: 0px 30px 30px 30px;
}

.wpcf7 div.wpcf7-mail-sent-ok {
  background: #398f14;
}

.screen-reader-response[role="alert"] {
  background: #de0000;
  margin-bottom: 10px;
  display: none;
}

.wpcf7 div {
  &.wpcf7-spam-blocked {
    background: #ffa500;
  }
  &.wpcf7-validation-errors {
    text-align: center;
    background: #F79400;
    display: inline-block;
    margin: 10px 0;
  }
}

.wpcf7-not-valid-tip {
  text-align: center;
  background: #F79400;
  display: inline-block;
  margin: 10px 0;
}

.wpcf7 div.wpcf7-mail-sent-ng, .screen-reader-response[role="alert"] ul {
  display: none;
}

::-moz-selection, ::selection {
  background: $pC;
  color: #fff;
}

.wc-terms-and-conditions {
  display: block;
}
.main-header{
  position: fixed;
  top: 0; right: 0;
  height: 100%;
  z-index: 9999;
  background: $grey;
  width: 300px;
  box-shadow: 0 0 40px transparentize($black, .8);
  .branding{
    display: block;
    margin: 50px auto;
    width: 170px;
  }
  nav{
    ul{
      li{
        a{
          display: block;
          position: relative;
          font-size: 1.15rem;
          padding: 15px 50px;
          padding-left: 75px;
          text-transform: lowercase;
          &:hover{
            background: $pC;
            color: #fff;
          }
          .count{
            position: absolute;
            right: 120px;
            top: 50%;
            margin-top: -20px;
            width: 20px;
            height: 20px;
            background: #F97033;
            color: #fff;
            font-size: .7em;
            text-align: center;
            border-radius: 30px;
            border-bottom-left-radius: 0;
          }
        }
        &.current-menu-item a{
          color: $pC;
          font-weight: bold;
          &:before{
            content: '';
            display: block;
            background: url("img/logo-symbol.svg") no-repeat center;
            background-size: contain;
            width: 10px;
            height: 10px;
            position: absolute;
            left: 35px;
            top: 50%;
            margin-top: -5px;
            @include  transition();
          }
          &:hover{
            color: #fff;
            &:before{
              transform: translateX(10px);
            }
          }
        }
      }
    }
    .cta-offer{
      background: $sC;
      text-align: center;
      font-size: 1rem;
      margin-top: 50px;
      color: #fff;
      display: block;
      padding: 20px;
      &:hover{
        background: darken($sC, 10%);
      }
    }
  }
  #map_canvas{
    width: 100%;
  }
}
.main-content{
  width: calc(100% - 300px);
  .inner-content{
    padding: 25px;
    font-size: 1.25rem;
    line-height: 2em;
    margin: 25px auto;
    ul li{
      list-style: disc;
      padding-left: 20px;
    }
    p, li{
      font-family: 'Dosis', sans-serif !important;
      color: #7E7E7E !important;
      span{
        font-family: 'Dosis', sans-serif !important;
        color: #7E7E7E !important;
      }
    }
    .heading{
      padding: 50px 0;
      .heading-title{
        font-size: 2rem;
      }
      .heading-description{
        font-size: 1rem;
      }
    }
  }
  .items{
    .item{
      list-style: none;
      background: #fafafa;
      a{
        padding: 10px;
        display: block;

        &:hover{
          opacity: .8;
          background: $sC;
          color: #fff;
        }
      }
      img{
        width: 100%;
        height: 50%;
        object-fit: cover;
        border-radius: 30px;
      }
      h4{
        border-bottom: 3px solid $sC;
        padding-bottom: 15px;
      }
    }
  }
}
.heading{
  padding: 75px 0;
  overflow: hidden;
  .heading-title{
    font-size: 3rem;
    color: $pC;
  }
  .heading-description{
    font-size: 1.5rem;
    position: relative;
    &:after{
      content: '';
      display: block;
      width: 40%;
      position: absolute;
      left: -20%;
      top: 150%;
      height: 3px;
      border-radius: 50px;
      background: $sC;
    }
  }
  .button{
    margin-top: 30px;
  }
  &.blue{
    color: #fff;
    padding: 50px 0;
    .heading-title{
      color: #fff;
    }
  }
}
.hero{
  .hero-slider{
    height: 45vh;
    img{
      width: 100%;
      height: 45vh;
      object-fit: cover;
    }
    &.bottom{
      height: 30vh;
      img{
        height: 30vh;
      }
    }
  }
  .hero-intro{
    .heading{
      padding: 20px 0;
      overflow: visible;
      .heading-title{
        color: #fff;
      }
    }
    background: $pC;
    color: #fff;
    padding: 25px 100px;
    margin-top: -100px;
    position: relative;
    &:before{
      content: '';
      display: block;
      background: url("img/logo-symbol.svg") no-repeat center;
      background-size: contain;
      width: 85px;
      height: 85px;
      position: absolute;
      right: -42px;
      top: -42px;
      transform: rotate(90deg);
    }
    p{
      margin-bottom: 30px;
    }
    a{
      color: #fff;
      text-decoration: underline !important;
      &:hover{
        color: $sC;
      }
    }
  }
  .hero-description{
    background: $grey;
    padding: 100px;
    h2{
      color: $pC;
    }
  }
}
.service{
  img{
    display: block;
    width: 50%;
    margin: 0 auto;
  }
}
.appointment{
  height: 80vh;
  margin-bottom: 0;
  background: url("img/appointment.jpg") no-repeat center;
  background-size: cover;
  margin-top: 100px;
  .appointment-info{
    padding: 50px;
    margin-top: -50px;
    color: #fff;
    background-color: transparentize($pC, .05);
    a:not(.button){
      color: #fff;
      border-bottom: 1px solid #fff;
    }
  }
}
footer{
  background: $pC;
  color: #fff;
  padding: 15px 50px;
  padding-top: 70px;
  margin-top: 50px;
  .first{
    margin-top: 75px;
  }
  .company{
    img{
      width: 70%;
      margin: 0 auto;
      margin-top: -175px;
    }
    .contact li{
      display: inline-block;
      margin-right: 30px;
    }
  }
  h3{
    margin-bottom: 20px;
  }
  ul li{
    line-height: 2em;
  }
  & a{
    color: #fff;
  }
  .social li{
    display: inline-block;
    margin-right: 10px;
  }
  .footer-bottom{
    margin-top: 30px;
  }
  .col.reign img{
    display: inline-block;
    width: 20px;
    margin-left: 10px;
    margin-top: -3px;
  }
}
.mobile-header{
  display: none;
  position: fixed;
  left: 0;
  padding: 15px;
  top: 0;
  z-index: 9999;
  width: 100%;
  background: #fafafa;
  box-shadow: 0 0 40px transparentize($black, .7);
  .branding{
    width: 75px;
    display: block;
    img{
      width: 100%;
      display: block;
    }
  }
  .mobile-trigger{
    color: $pC;
    background: transparent;
    border: 0;
    padding: 16px;
    font-size: 1.75rem;
    .fa-times{
      display: none;
    }
    &.active{
      color: $sC;
      .fa-times{
        display: block;
        position: relative;
        top: 5px;
        font-size: 2rem;
      }
      .fa-ellipsis-v{
        display: none;
      }
    }
  }
}
@media (min-width: 1600px){
  .container {
    max-width: 1550px;
  }
}
@media (max-width: 1440px){
  .main-header{
    .branding{
      margin: 25px auto;
    }
  }
  footer{
    .company img{
      margin: 0 auto;
    }
  }
}
@media (max-width: 1440px){
  .hero .hero-intro{
    padding: 50px;
  }
}
@media (max-width: 1024px){
  .main-header nav ul li a{
    font-size: 1.15rem;
    padding: 10px 50px;
    padding-left: 75px;
  }
  .main-header nav .cta-offer{
    margin-top: 15px;
  }
  .heading{
    padding: 50px;
  }
  footer{
    h3{
      margin-bottom: 10px;
    }
    .first{
      margin-top: 0;
    }
  }
  .service img{
    width: 90%;
  }
}
@media (max-width: 992px){
  .main-content{
    width: 100%;
    .inner-content{
      padding: 30px;
      margin: 25px auto;
    }
    .items item img{
      height: auto;
    }
  }
  .hero .hero-intro:before{
    right: auto;
  }
  .mobile-header{
    display: flex;
  }
  .main-header{
    width: 260px;
    z-index: 9998;
    transform: translateX(100%);
    @include transition();
    &.active{
      transform: translateX(0);
    }
    .branding{
      display: none;
    }
    nav{
      margin-top: 170px;
      ul li a{
        font-size: 1.15rem;
        padding: 15px 50px;
        padding-left: 75px;
        .count{
          right: 90px;
        }
      }
    }
  }
}
@media (max-width: 900px){
  .hero .hero-slider{
    height: 500px;
    img{
      height: 500px;
    }
  }
  .heading{
    .heading-title{
      font-size: 2rem;
    }
  }
  .main-content {
    .inner-content {
      padding: 15px;
      margin: 15px auto;
      .heading{
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .hero .hero-description{
    padding: 50px;
  }
  footer{
    padding: 15px;
  }
}